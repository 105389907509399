<div
  class="cl-ui-components cl-flex cl-flex-col cl-max-w-[1217px] cl-pb-4 md:cl-pb-8 cl-mx-auto cl-w-full"
  [formGroup]="changeDataForm"
>
  @if (isEuForm()) {
    <div class="cl-mb-3">
      <cl-input
        formControlName="firstName"
        placeholder="{{ 'first_name' | translate }}"
        label="{{ 'first_name' | translate }}"
        errorHelpText="{{ 'first_name_error' | translate }}"
      ></cl-input>
    </div>
    <div class="cl-mb-3">
      <cl-input
        formControlName="lastName"
        placeholder="{{ 'last_name_label' | translate }}"
        label="{{ 'last_name' | translate }}"
        errorHelpText="{{ 'last_name_error' | translate }}"
      ></cl-input>
    </div>
  }
  <div class="cl-mb-3">
    <cl-input
      formControlName="email"
      placeholder="{{ 'email_label' | translate }}"
      label="{{ 'email' | translate }}"
      errorHelpText="{{ 'email_error' | translate }}"
    ></cl-input>
  </div>
  <div class="cl-mb-3">
    <cl-input
      formControlName="phone"
      placeholder="{{ 'phone_number_label' | translate }}"
      label="{{ 'phone_number' | translate }}"
      errorHelpText="{{ 'phone_number_error' | translate }}"
    ></cl-input>
  </div>
  <div class="cl-flex cl-justify-between">
    <cl-button
      (click)="onClose()"
      [expand]="editDataButtonExpand"
      [size]="editDataButtonSize"
      [variant]="closeDataChangeModeButtonVariants"
    >
      {{ closeButtonTransKey | translate }}
    </cl-button>

    <cl-button
      (click)="onSubmit()"
      [expand]="editDataButtonExpand"
      [disabled]="!changeDataForm.valid || (requiredDataChange ? !changeDataForm.dirty : false)"
      [size]="editDataButtonSize"
    >
      <p class="cl-text-fg-white">
        {{ submitButtonTransKey | translate }}
      </p>
    </cl-button>
  </div>
</div>
