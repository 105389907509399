import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, Input, OnInit, inject, signal } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  CL_BUTTON_EXPAND,
  CL_NOTIFICATION_VARIANT,
  CL_SIZE,
  ClButtonModule,
  ClButtonSize,
  ClInputModule,
  ClNotificationMessageModule,
} from '@circlek-global/eca-lightning-components';

import { NgForOf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api/api.service';
import { LayoutComponent } from '../components/layout/layout.component';
import { LoaderService } from '../components/loader/loader.service';
import { setUserData } from '../state/user/user.actions';
import { UserData } from '../state/user/user.model';

interface GetUserResponseData {
  data: UserData;
}

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    RouterOutlet,
    ClButtonModule,
    TranslateModule,
    LayoutComponent,
    ClInputModule,
    ReactiveFormsModule,
    ClNotificationMessageModule,
    NgForOf,
  ],
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private apiService = inject(ApiService);
  private store = inject(Store);
  private router = inject(Router);
  private translate = inject(TranslateService);
  loader = inject(LoaderService);

  @Input() editDataButtonSize: ClButtonSize = CL_SIZE.XXL;
  @Input() editDataButtonExpand: CL_BUTTON_EXPAND = CL_BUTTON_EXPAND.FULL;
  @Input() notificationSuccessVariant: CL_NOTIFICATION_VARIANT = CL_NOTIFICATION_VARIANT.SUCCESS;

  user = signal<UserData | undefined>(undefined);
  error = signal(false);

  region = this.apiService.region();

  ngOnInit() {
    this.loader.setLoader(true);
    this.getUserData();
  }

  getUserData() {
    const subscription = this.httpClient
      .get<GetUserResponseData>(`${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/get`, {
        withCredentials: true,
      })
      .subscribe({
        next: (responseData) => {
          this.user.set(responseData.data);
          this.loader.setLoader(false);

          this.store.dispatch(setUserData({ data: responseData.data }));
        },
        error: (err) => {
          // TODO: Log error to Sentry
          console.error({ err });

          this.loader.setLoader(false);
          this.error.set(true);
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  tryAgain() {
    this.error.set(false);
    this.loader.setLoader(true);
    this.getUserData();
  }

  toggleEditMode() {
    this.router.navigate([this.translate.currentLang, 'profile', 'change-data']);
  }

  changePassword() {
    this.router.navigate([this.translate.currentLang, 'profile', 'change-password']);
  }
}
