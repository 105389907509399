import { HttpClient } from '@angular/common/http';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs';
import { ApiService } from '../api/api.service';
import { LoaderService } from '../components/loader/loader.service';
import { ToastService } from '../components/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private loaderService = inject(LoaderService);
  private apiService = inject(ApiService);
  private toast = inject(ToastService);

  private isAuthorized = signal(false);

  isUserAuthorized = computed(() => this.isAuthorized());

  checkIfAuthorized() {
    this.loaderService.setLoader(true);
    const subscription = this.httpClient
      .get(`${this.apiService.createBffUrl({ path: 'auth', useRegion: true })}/check`, {
        withCredentials: true,
      })

      .pipe(finalize(() => this.loaderService.setLoader(false)))
      .subscribe({
        next: () => {
          this.isAuthorized.set(true);
          this.router.navigate(['/en/profile']);
        },
        error: () => {
          this.isAuthorized.set(false);
          this.router.navigate(['/auth']);
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  getAccessToken(authorizationCode: string) {
    this.loaderService.setLoader(true);
    const subscription = this.httpClient
      .post(
        `${this.apiService.createBffUrl({ path: 'auth', useRegion: true })}/token?authorizationCode=${authorizationCode}`,
        {},
        { withCredentials: true },
      )
      .pipe(finalize(() => this.loaderService.setLoader(false)))
      .subscribe({
        next: () => {
          this.isAuthorized.set(true);
          this.router.navigate(['/en/profile']);
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  logout() {
    const subscription = this.httpClient
      .post(`${this.apiService.createBffUrl({ path: 'auth', useRegion: false })}/logout`, {}, { withCredentials: true })
      .subscribe({
        next: () => {
          this.isAuthorized.set(false);
          this.router.navigate(['/auth']);
        },
        error: (err) => {
          // TODO: Log error to Sentry
          this.toast.showError('something_went_wrong');
          console.error({ err });
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }
}
