import {UserData} from "../state/user/user.model";
import {finalize} from "rxjs";
import {inject, Injectable, signal, WritableSignal} from "@angular/core";
import {LoaderService} from "../components/loader/loader.service";
import {FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ApiService, Region} from "./api.service";
import {ToastService} from "../components/toast/toast.service";
import reportSentryError from "../../helpers/reportSentryError";

@Injectable({ providedIn: 'root' })
export class UpdateProfileService {
  private loader = inject(LoaderService);
  private httpClient = inject(HttpClient);
  private apiService = inject(ApiService);
  private toast = inject(ToastService);
  isEuForm = signal(this.apiService.region() !== Region.US);

  updateProfile(changeDataForm: FormGroup, userData: UserData | undefined, verifyOtp: WritableSignal<boolean>, onDataChangeSuccess: () => void) {
    this.loader.setLoader(true);

    const changes: Partial<UserData> = {};

    const formValues = changeDataForm.value;
    const user = userData ?? {};

    Object.keys(formValues).forEach((field: string) => {
      const newValue = formValues[field];

      if (newValue !== user[field as keyof UserData]) {
        changes[field as keyof UserData] = newValue;
      }
    });

    const phoneChanged = changes.phone !== undefined;
    const emailChanged = changes.email !== undefined;

    if (this.isEuForm()) {
      const lastNameChanged = changes.lastName !== undefined;
      const firstNameChanged = changes.firstName !== undefined;

      if ((phoneChanged || emailChanged) && (!firstNameChanged || !lastNameChanged)) {
        changes.firstName = user?.firstName;
      }
    }

    const subscription = this.httpClient
      .post(`${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/update`, changes, {
        withCredentials: true,
      })
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: () => {
          if (changes.phone) {
            verifyOtp.set(true);
          } else {
            onDataChangeSuccess();
          }
        },
        error: (error) => {
          if (error.error.path === '/v1/me') {
            this.toast.showError('user_email_already_exists');
          } else {
            reportSentryError({
              error,
              customErrorName: 'On Change Data Submit Error',
              endpointName: 'on-change-data-submit',
            });

            this.toast.showError(error.error.errorCode);
          }
          console.error({ error });
        },
      });
  }
}
