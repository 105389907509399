import { Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SignUpSuccessDataType } from 'src/types/authServiceEventTypes';
import { AuthService } from './auth.service';
import { ApiService, Region } from '../api/api.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [],
  templateUrl: './auth.component.html',
})
export class AuthComponent {
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private domSanitizer = inject(DomSanitizer);
  private authServiceAppId =
    this.apiService.region() === Region.EU ? environment.euAuthServiceAppId : environment.usAuthServiceAppId;

  authServiceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
    `${environment.authServiceDomain}/?appId=${this.authServiceAppId}`,
  );

  ngOnInit() {
    window.addEventListener('message', this.handleIframeEvent.bind(this), false);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.handleIframeEvent.bind(this), false);
  }

  handleIframeEvent({ origin, data }: MessageEvent<SignUpSuccessDataType>) {
    if (origin === environment.authServiceDomain && data.eventName === 'signUpSuccess') {
      this.authService.getAccessToken(data.authorizationCode);
    }
  }
}
